/*--------------------------------------------------------------
>>> TABLE OF CONTENTS:
----------------------------------------------------------------
1.0 Variables and Mixins
2.0 Typography
3.0 Body
4.0 Header
5.0 Content
 5.1 Blog
6.0 Footer
--------------------------------------------------------------*/


/*--------------------------------------------------------------
1.0 Variables and Mixins
--------------------------------------------------------------*/

/*--------------------------------------------------------------
2.0 Typography
--------------------------------------------------------------*/

/*--------------------------------------------------------------
3.0 Body
--------------------------------------------------------------*/

body {
	overflow-x: hidden;
}

/*--------------------------------------------------------------
4.0 Header
--------------------------------------------------------------*/

nav.navbar {
	background:$primary;
	padding: 0;
	.container {
		flex-direction:column;
		align-items: flex-start;
	}
}
#header-area-1 {
	padding: 16px 0;
	color: #666;
	a {
		text-decoration: none;
		color: #666;
	}
}
#header-contact-us-button {
	font-size: 18px;
	line-height: 1.8em;
	color: $primary;
	background-color: #FFF;
	padding: 12px 24px;
	&:hover {
		text-decoration: none;
	}
	i {
		font-style: normal !important;
		font-weight: 900;
		margin-left: 5px;
		font-size: 20px;
	}
}
.wrapper-header {
	width: 100%;
	height: 110px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	position: relative;
}
.navbar-brand img {
	margin-top:20px;
}
#navbarNavDropdown {
	ul {
		li {
			a {
				text-transform: uppercase;
				color: #FFF;
				padding: 15px;
				&:hover {
					background: #FFF;
					color: $primary;
				}
			}
			ul {
				margin: 0;
				padding: 0;
				background: $secondary;
				border-radius: 0;
				li {
					a {
						&:hover {
							background: $primary;
							color: #FFF;
						}
					}
				}
			}
		}
	}
}

@include media-breakpoint-down(md) {
	nav.navbar .container {
		flex-direction: row;
		align-items: center;
		.wrapper-header {
			width: 90%;
		}
		button {
			padding:0;
			margin-right: 10px;
			.navbar-toggler-icon {
				width: 2em;
				height: 2em;
				background-image:url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(255, 255, 255, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
			}	
		}
	}
}
@include media-breakpoint-down(sm) {
	nav.navbar .container .wrapper-header {
		width: 88%;
	}
	#header-contact-us-button {
		padding: 8px 12px;
		margin-right: 10px;
	}
}
@include media-breakpoint-down(xs) {
	#header-contact-us-button {
		height:38px;
		display:block;
		font-size: 0;
		i {
			font-size:20px;
			display: block;
			margin: 0;
			line-height: 1;
		}
	}
}
@media (max-width: 450px) {
	nav.navbar .container .wrapper-header {
		width: 86%;
	}
}
@media (max-width: 380px) {
	nav.navbar .container .wrapper-header {
		width: 84%;
	}
}

/*--------------------------------------------------------------
5.0 Content
--------------------------------------------------------------*/

#main {
	width:100%;
}

/*--------------------------------------------------------------
5.1 Blog
--------------------------------------------------------------*/

#index-wrapper .row,
#index-wrapper .row article header,
#index-wrapper .row article header img,
body.single article .entry-header,
body.single article .entry-title,
body.single article .entry-meta,
body.single article .entry-content,
body.single article .entry-footer {
	margin-bottom: $grid-gutter-width;
}

/*--------------------------------------------------------------
6.0 Footer
--------------------------------------------------------------*/

#wrapper-footer {
	background: #00457e;
	padding: 25px 0px 25px 0px;
	.widget_nav_menu {
		color: #FFF;
		ul.menu {
			margin: 20px 0 0;
			padding: 20px 0 0;
			list-style: none;
			li {
				a {
					color: #FFF;
				}
			}
		}
	}
	.widget_custom_html {
		color: #FFF;
		.custom-html-widget {
			margin: 20px 0 0;
			padding: 20px 0 0;
		}
	}
}
#footer-area-2 {
	padding: 16px 0;
	color: #666;
}
